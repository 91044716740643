import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    container,
    grid,
    titleText,
    subtitleText,
    contentText,
    button,
    slider,
    image,
} from './manufacturer-slider.module.scss';
import { ISection } from '../../models/section.model';
import { IPartnerLogotype } from '../../models/partner-logotype.model';

import Section from '../hoc/section';
import Slider from '../hoc/slider';
import ConditionalLink from '../hoc/conditional-link';

export interface ISectionProduct extends ISection {
    items: {
        manufacturers: IPartnerLogotype[];
    };
}

interface IManufacturerSliderProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: ISectionProduct;
}

const sliderBreakpoints = {
    0: {
        spaceBetween: 50,
    },
    600: {
        spaceBetween: 100,
    },
};

const ManufacturerSlider: React.FC<IManufacturerSliderProps> = ({
    className = '',
    section,
    TitleTag = 'h2',
}) => {
    const {
        sectionId,
        content: { texts, buttons },
        items: { manufacturers },
        css,
        style,
    } = section;

    const manufacturersWithImages = manufacturers.filter((manufacturer) => !!manufacturer.media[0]);

    return (
        <Section
            sectionId={sectionId}
            className={`${container} ${className}`}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
                description: contentText,
                button: button,
            }}
            TitleTag={TitleTag}
            title={texts[0]}
            subtitle={texts[1]}
            description={texts[2]}
            css={css}
            style={style}
            buttonText={buttons[0]?.text}
            buttonUrl={buttons[0]?.url}
        >
            <div className={slider}>
                <Slider
                    spaceBetween={50}
                    rewind={true}
                    slidesPerView={'auto'}
                    breakpoints={sliderBreakpoints}
                    autoplay={{
                        delay: 5000,
                        pauseOnMouseEnter: true,
                    }}
                >
                    {manufacturersWithImages.map((manufacturer) => {
                        return (
                            <ConditionalLink
                                type={'remoteUrl'}
                                key={manufacturer.logoId}
                                to={manufacturer.url}
                                blank={true}
                            >
                                <Image
                                    media={manufacturer.media}
                                    className={image}
                                    objectFit={'contain'}
                                />
                            </ConditionalLink>
                        );
                    })}
                </Slider>
            </div>
        </Section>
    );
};

export default ManufacturerSlider;
